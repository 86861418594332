<template>
  <div class="moduleManagement">
    <el-table :data="tableData">
      <el-table-column label="模块名称"
                       prop="title"
                       width="200px">
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <el-checkbox :checked="selected"
                       class="checkbox-name"
                       @change="selectedChange">功能名称</el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox-group v-model="scope.row.selecedList"
                             @change="modifyAuthority">
            <el-checkbox v-for="nav in scope.row.navsList"
                         :key="nav.navId2"
                         :label="nav.navId2">{{ nav.title }}</el-checkbox>
          </el-checkbox-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "moduleManagement",
  data() {
    return {
      tableData: [],
      selected: false,
      allModuleRes: [],
      hasModuleRes: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    to.meta.name = `编辑权限 `;
    // to.meta.name = `编辑权限 / ${to.query.name}`;
    next();
  },
  created() {
    this.loadAuthority();
  },
  methods: {
    loadAuthority() {
      // 查询角色模块管理能看到的模块
      this.$post("/media/queryGlSchoolModule.do", {
        roleId: this.$route.query.id,
      })
        .then((moduleRes) => {
          this.tableData = [];
          this.allModuleRes = [];
          moduleRes.data.forEach((menu) => {
            let newMenu = menu;
            newMenu.selecedList = [];
            this.tableData.push(newMenu);
            menu.navsList.forEach((nav) => {
              this.allModuleRes.push(nav.navId2);
            });
          });
          //角色拥有的权限
          this.$post("/media/queryGlRoleSchoolModule.do", {
            roleId: this.$route.query.id,
          })
            .then((res) => {
              this.hasModuleRes = [];
              let i = 0;
              res.data.forEach((selected) => {
                for (let index = 0; index < this.tableData.length; index++) {
                  let module = this.tableData[index];
                  if (module.navId === selected.navId) {
                    module.selecedList.push(selected.navId2);
                    this.hasModuleRes.push(selected.navId2);
                    break;
                  }
                }
              });
              if (this.allModuleRes === this.hasModuleRes) {
                this.selected = true;
              }
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    modifyAuthority() {
      let ids = [];
      this.tableData.forEach((selected) => {
        ids = ids.concat(selected.selecedList);
      });

      this.$post("/media/updateGlRoleSchoolModule.do", {
        roleId: this.$route.query.id,
        moduleInfoIds: ids.join(),
      })
        .then((res) => {
          this.$message.success("修改成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    selectedChange(event) {
      this.selected = event;
      let moduleInfoIds;
      if (this.selected) {
        moduleInfoIds = this.allModuleRes.join();
      } else {
        moduleInfoIds = "";
      }
      console.log(moduleInfoIds);
      this.$post("/media/updateGlRoleSchoolModule.do", {
        roleId: this.$route.query.id,
        moduleInfoIds: moduleInfoIds,
      })
        .then((res) => {
          this.$message.success("修改成功");
          this.loadAuthority();
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-name {
  font-weight: 600;
}
</style>
